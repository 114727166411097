import React from 'react';
import { graphql, navigate, PageProps } from 'gatsby';

// components
import { Layout, Seo, Title, PageContent } from '@/components';

/**
 * The template for pages automatically generated from wpPage
 */
const PageTemplate = ({ data }: PageProps<Queries.PageTemplateQuery>) => {
  if (!data?.wpPage) {
    navigate('/404/');
    return null;
  }

  const { content, uri, title } = data.wpPage;

  return (
    <Layout withSidebar>
      <Seo title={title} pathname={uri} />
      <PageContent>
        <Title>{title}</Title>
        <div dangerouslySetInnerHTML={{ __html: content || '' }} />
      </PageContent>
    </Layout>
  );
};

export default PageTemplate;

// page query
export const pageTemplateQuery = graphql`
  query PageTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      content
      title
      uri
    }
  }
`;
